<script>
import CategoriesServiceApi from "@/service/api/categories";
import ProductServiceApi from "@/service/api/product";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import tags from "@/service/prod-tags";
export default {
  page: {
    title: "Product",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Products",
      items: [
        {
          text: "Ecommerce",
        },
        {
          text: "Products",
          active: true,
        },
      ],
      tagsSelect: [],
      categories: null,
      secondary_cateogry: null,
      products: null,
      perPage: 15,
      tags: null,
      currentPage: 1,
      rows: null,
      total: null,
      listProductTable: false,
      pageOptions: [15, 25, 50, 100],
      showAddform: false,
      filter: null,
      filterOn: [],
      value: null,
      myFiles: [],
      mainCatSelect: null,
      primaryCatSelect: null,
      primaryCategory: null,
      secondaryCategory: null,
      only_size: false,
      is_collectibles: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name_en", sortable: true, label: this.$t("brands.table.name") },
        { key: "name_ar", sortable: true },
        { key: "shop_id", sortable: true },
        { key: "description_en", sortable: true },
        { key: "description_ar", sortable: true },
        { key: "category_id", sortable: true },
        { key: "subcategory_id", sortable: true },
        {
          key: "sort_index",
          sortable: true,
        },
          { key: "bestseller", sortable: true },
          { key: "special", sortable: true },
          { key: "isactive", sortable: true },
      ],
      genderOpt: {
        M: "Men",
        F: "Women",
        C: "Children",
        P: " Preschool",
        I: "Toddler",
        T: "Infant",
      },
      productForm: {
        name: null,
        desc: null,
        color: null,
        SKU: null,
        condition: null,
        retail_price: null,
        release_date: null,
        image_path: null,
        gender: null,
        grade: null,
        secondary_id: null,
      },
    };
  },
  computed: {
    imageUrl() {
      if (
        this.productForm.image_path &&
        this.productForm.image_path.files[0] &&
        this.productForm.image_path.files[0].file
      )
        return URL.createObjectURL(this.productForm.image_path.files[0].file);
      else {
        return null;
      }
    },
    totalPages() {
      return parseInt(this.rows / this.perPage);
    },

    mainCategory() {
      return this.categories.map((item) => {
        return {
          label: `${item.name_en}-${item.name_ar}`,
          value: item.id,
        };
      });
    },
    primaryCategoryOpt() {
      if (this.primaryCategory) {
        return this.primaryCategory.map((item) => {
          return {
            label: `${item.name}`,
            value: item.id,
          };
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    currentPage(val) {
      this.loadProducts(val);
    },
    mainCatSelect(val) {
      this.primaryCategory = this.filterPrimaryCategory(val);
      this.secondaryCategory = [];
      switch (val) {
        case "1":
          this.tags = tags.Sneakers;
          this.only_size = true;
          this.tagsSelect = [];
          break;
        case "2":
          this.tags = tags.Streetwear;
          this.only_size = true;
          this.tagsSelect = [];
          break;
        case "3":
          this.tags = tags.Collectibles;
          this.tagsSelect = [];
          this.is_collectible = true;
          break;
        default:
          this.tags = null;
          this.tagsSelect = [];
          break;
      }
    },
    primaryCatSelect(val) {
      this.secondaryCategory = this.filterSecondaryCategory(val);
    },
  },
  methods: {
    loadProducts(page) {
      ProductServiceApi.getAllProducts(page).then((response) => {
        this.products = response.data.data;
        this.rows = response.data.total;
        this.perPage = response.data.per_page;
      });
    },
    productSelected(val) {
      const productId = val.pop().id;
      this.openProductDetails(productId);
    },
    productAddMessage(data, variant = "success") {
      this.$bvToast.toast(`Product ${data} is added !`, {
        title: `Product added`,
        variant: variant,
        solid: true,
      });
    },
    productAddErrorMessage(data, variant = "danger") {
      this.$bvToast.toast(`Product is not added!, failed with error: ${data}`, {
        title: `Opration Failed`,
        variant: variant,
        solid: true,
      });
    },

    submitProductHandler(data) {
      var form_data = new FormData();
      form_data.append("name_en", data.name);
      form_data.append("name_ar", data.name_ar);
      form_data.append("image", this.productForm.image_path.files[0].file);
      if(data.description_en) form_data.append("description_en", data.description_en)
      if(data.description_ar) form_data.append("description_ar", data.description_ar)
      
      form_data.append("shop_id", data.shop_id);
      form_data.append("category_id", data.category_id);
      form_data.append("subcategory_id", data.subcategory_id);
      form_data.append("sort_index", data.sort_index);
      form_data.append("bestseller", data.bestseller == "Yes" ? 1 : 0);
      form_data.append("special", data.special == "Yes" ? 1 : 0);
      form_data.append("isactive", data.isactive == "Yes" ? 1 : 0);

      form_data.append("action", "add");

      if (!this.only_size) {
        form_data.append("only_size", true);
      } else {
        this.tagsSelect.forEach((item) => {
          form_data.append("sizelist[]", item);
        });
      }

      ProductServiceApi.productAction(form_data).then((response) => {
        var productName = response.data.name_en;
        this.productAddMessage(productName);
          this.productForm = {
            name: null,
            desc: null,
            color: null,
            SKU: null,
            condition: null,
            retail_price: null,
            release_date: null,
            image_path: null,
            gender: null,
            grade: null,
            secondary_id: null,
          };
          this.$formulate.reset("productAddForm");
        this.loadProducts(this.currentPage);
      }).catch(err => {
        console.log('=-=-=-=-=-=CAUGHT ERROR WHEN CREATING PRODUCT-=-=-=-=-=-=-=-=-=-');
        console.log(err);
        console.log('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
        this.productAddErrorMessage(err);
      });
    },

    filterPrimaryCategory(id = null) {
      if (id) {
        let primeData = this.categories.filter(
          (item) => item.id === parseInt(id)
        );
        if (primeData.length > 0) {
          return primeData[0].primary;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    openProductDetails(id = null) {
      if (id) {
        this.$router.push({ name: "product detail", params: { id: id } });
      } else {
        return;
      }
    },
    filterSecondaryCategory(id = null) {
      if (id && this.primaryCategory) {
        let secondData = this.primaryCategory.filter(
          (item) => item.id === parseInt(id)
        );
        if (secondData.length > 0) {
          return secondData[0].secondary.map((item) => {
            return {
              label: `${item.name}`,
              value: item.id,
            };
          });
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
  },
  mounted() {
    CategoriesServiceApi.getAllCategories().then((response) => {
      console.log('=-=-=-=-=-=-=-=Response data-=-=-=-=-=-=-=-');
      console.log(response);
      console.log('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
      this.categories = response.data;
    });
    this.loadProducts(this.currentPage);
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-3 col-lg-4">
        <div class="card">
          <div class="card-header bg-transparent border-bottom">
            <h5 class="mb-0">Filters</h5>
          </div>

          <div class="card-body">
            <h5 class="font-size-14 mb-3">Categories</h5>

            <div
              id="accordion"
              class="custom-accordion categories-accordion mb-3"
            >
              <div
                class="categories-group-card"
                v-for="category in categories"
                :key="category.id"
              >
                <a
                  v-b-toggle="`maincategory${category.id}`"
                  class="categories-group-list"
                >
                  {{ category.name_en }}
                  <i class="mdi mdi-minus float-right accor-plus-icon"></i>
                </a>

                <b-collapse
                  :id="'maincategory' + category.id"
                  data-parent="#accordion"
                  accordion="my-accordion"
                >
                  <div
                    class="list-unstyled mb-0"
                    v-for="primary in category.primary"
                    :key="primary.id"
                  >
                    <div
                      id="secondary_accordion"
                      class="custom-accordion categories-accordion"
                    >
                      <div style="padding-left:18px;">
                        <a
                          v-b-toggle="`primarycategory${primary.id}`"
                          class="categories-group-list"
                        >
                          {{ primary.name }}
                          <i
                            class="mdi mdi-minus float-right accor-plus-icon"
                          ></i>
                        </a>

                        <b-collapse
                          :id="'primarycategory' + primary.id"
                          data-parent="#secondary_accordion"
                          accordion="secondary_accordion"
                        >
                          <div>
                            <ul
                              class="list-unstyled categories-list mb-0"
                              v-for="secondary in primary.secondary"
                              :key="secondary.id"
                              style="margin:5px;padding:0;"
                            >
                              <li>
                                <a href="#">
                                  {{ secondary.name }}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </b-collapse>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="product-add-st">
          <b-button variant="light" @click="() => (this.showAddform = true)">
            <i class="ri-add-box-fill align-middle"></i> Add Product</b-button
          >
        </div>
        <div class="card">
          <div class="card-body" id="change-product-view">
            <FormulateInput
              v-model="listProductTable"
              type="checkbox"
              @click="() => (this.showAddform = false)"
              label="view as table"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div v-if="showAddform">
          <div class="card">
            <div class="card-body" id="change-product-view">
              <FormulateForm
                name="productAddForm"
                @submit="submitProductHandler"
                v-model="productForm"
              >
                <div style="margin: 10px 30px">
                  <div class="row">
                    <div class="col-md-6">
                      <FormulateInput
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="Product name here"
                        validation="required"
                      />
                      <FormulateInput
                        type="text"
                        name="name_ar"
                        label="Name (in Arabic)"
                        placeholder="Product name here (Arabic)"
                        validation="required"
                      />
                      <FormulateInput
                        type="image"
                        name="image_path"
                        label="Select an image to upload"
                        help="Select a png, jpg or gif to upload."
                        validation="mime:image/jpeg,image/png,image/gif|required"
                      />
                    </div>
                    <div class="col-md-6">
                      <div class="product-preview-form">
                        <div class="product-box" style="height:262px;">
                          <div class="product-img mb-1">
                            <img
                              :src="imageUrl"
                              v-if="imageUrl"
                              onerror="javascript:this.src=''"
                              alt="Please upload image"
                              class="img-fluid mx-auto d-block"
                              style="height:148px;width:158px;"
                            />
                            <img
                              v-else
                              src="@/assets/images/noimage.png"
                              alt="Please upload image"
                              class="img-fluid mx-auto d-block"
                              style="height:148px;width:158px;"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <FormulateInput
                        type="textarea"
                        name="description_en"
                        label="Description"
                        placeholder="Description here"
                        validation="required"
                      />
                      <FormulateInput
                        type="textarea"
                        name="description_ar"
                        label="Description (Arabic)"
                        placeholder="Description here (Arabic)"
                        validation="required"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <FormulateInput
                        type="number"
                        name="shop_id"
                        label="Shop ID"
                        validation="required"
                        placeholder="Shop ID here"
                        min="0"
                      />
                    </div>
                    <div class="col-md-6">
                      <FormulateInput
                        name="bestseller"
                        :options="['Yes', 'No']"
                        type="select"
                        validation="required"
                        label="Best Seller"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <FormulateInput
                        type="number"
                        name="category_id"
                        label="Category ID"
                        validation="required"
                        placeholder="Category ID here"
                      />
                    </div>
                    <div class="col-md-6">
                     <FormulateInput
                        type="number"
                        name="subcategory_id"
                        label="Subcategory ID"
                        validation="required"
                        placeholder="Subcategory ID here"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <FormulateInput
                        name="special"
                        :options="['Yes', 'No']"
                        type="select"
                        validation="required"
                        label="Special"
                      />
                    </div>
                    <div class="col-md-6">
                      <FormulateInput
                        name="isactive"
                        :options="['Yes', 'No']"
                        type="select"
                        validation="required"
                        label="Is Active ?"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <FormulateInput
                        type="number"
                        name="sort_index"
                        label="Sort Index"
                        validation="required"
                        placeholder="Sort Index here"
                        min="0"
                      />
                    </div>
                  </div>
                </div>

                <div class="m-4" style="padding-bottom:50px;">
                  <div class="mt-4 text-center m-2">
                    <button
                      class="btn btn-primary w-md waves-effect waves-light float-left"
                      type="submit"
                    >
                      Add
                    </button>

                    <button
                      class="btn btn-primary w-md waves-effect waves-light float-right"
                      type="button"
                      @click="() => (this.showAddform = false)"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </FormulateForm>
            </div>
          </div>
        </div>

        <div class="card" v-else>
          <div class="card-body">
            <div>
              <div class="row mt-4" style="padding-top:10px;">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>

              <div
                class="row no-gutters"
                style="padding-top:10px;"
                v-if="!listProductTable"
              >
                <div
                  class="col-xl-4 col-sm-6"
                  v-for="product in products"
                  :key="product.id"
                >
                  <div
                    class="product-box"
                    style="height:300px;"
                    @click="openProductDetails(product.id)"
                  >
                    <div class="product-img">
                      <img
                        :src="product.coverimage"
                        alt
                        class="img-fluid mx-auto d-block"
                        style="height:148px"
                      />
                    </div>

                    <div class="text-center">
                      <p class="font-size-16 mt-4" v-if="product.category_id">
                        {{ product.name_en }}
                      </p>
                      <h5 class="font-size-15">
                        <span class="text-dark">{{ product.name }}</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div style="padding-top:10px;" v-else>
                <div class="table-responsive mb-0" v-if="products">
                  <b-table
                    :items="products"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @row-selected="productSelected"
                    selectable
                  >
                  </b-table>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-sm-6">
                  <div>
                    <p class="mb-sm-0 mt-2">
                      Page
                      <span class="font-weight-bold">{{ currentPage }}</span> Of
                      <span class="font-weight-bold">{{ totalPages + 1 }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="float-sm-right">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style>
#change-product-view .formulate-input-element--checkbox {
  margin-top: 8px;
}
.product-add-st {
  margin: 10px auto;
}
.product-add-st button {
  width: 100%;
  color: brown;
}
.product-preview-form {
  height: 100%;
  overflow: hidden;
}
.product-tag-st {
  margin: 30px 0px;
}

.product-tag-st .custom-control-inline {
  width: 60px;
}
.product-box {
  cursor: pointer;
}
</style>
